.datepickerHinoConnect .MuiOutlinedInput-adornedEnd {
  padding-right: 0 !important;
}

.datepickerHinoConnect .MuiIconButton-root:hover {
  background-color: transparent;
}

.datepickerHinoConnect .MuiIconButton-root {
  padding-right: 3px;
}
