.color-disabled-connect {
  input[type='text']:disabled,
  select:disabled,
  .MuiFormLabel-root.Mui-disabled,
  .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense,
  .MuiInputBase-input.Mui-disabled,
  .MuiSelect-select.MuiSelect-select,
  .MuiFormControlLabel-label.Mui-disabled {
    color: #263238;
  }

  input[type='text']:not(.Mui-disabled),
  select:not(.Mui-disabled),
  textarea:not(.Mui-disabled),
  .MuiInputBase-multiline.MuiInputBase-marginDense:not(.Mui-disabled) {
    background-color: antiquewhite;
  }

  .MuiButton-label {
    color: #3f51b5;
  }

  .MuiButton-containedPrimary .MuiButton-label {
    color: white;
  }

  .MuiButton-contained.Mui-disabled {
    background-color: #3f51b5 !important;
  }

  .MuiButton-outlined.Mui-disabled,
  .MuiButton-containedPrimary {
    border-color: #3f51b5 !important;
  }

  .makeStyles-buttonOrange-533.MuiButton-containedPrimary {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
  }
}
