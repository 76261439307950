.custom-pagination-outbound {
  height: 30px;
}

.custom-pagination-outbound .page-link {
  color: #000000;
}

.custom-pagination-outbound .page-item.active .page-link {
  z-index: 3;
  cursor: pointer;
  color: #fff;
  background-color: #2d2121;
  border-color: #000000;
}
.custom-pagination-outbound .page-item:first-child .page-link:first-child {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.custom-pagination-outbound .page-item:last-child .page-link:last-child {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.custom-hover:hover button {
  background-color: rgb(248, 234, 221);
}

select.custom-select {
  width: 85px;
  background-image: linear-gradient(45deg, transparent 50%, #c9c5c5 50%),
    linear-gradient(135deg, #c9c5c5 50%, transparent 50%),
    linear-gradient(to right, #7a7676, #7a7676);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}
