.paddingBottomTopUnset {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.paddingBottomUnset {
  padding-top: 4px !important;
  padding-bottom: 0 !important;
}
.paddingTopUnset {
  padding-top: 0 !important;
  padding-bottom: 4px !important;
}

.custom-pagination .page-link {
  border: 0px;
  color: #000000;
}

.custom-pagination .page-item.active .page-link {
  z-index: 3;
  cursor: pointer;
  color: #fff;
  background-color: #f96f0f;
  border-color: #007bff;
}

.call-list-jdi .custom-pagination .page-item.active .page-link {
  z-index: 3;
  cursor: pointer;
  color: #fff;
  background-color: #01b0f0;
  border-color: #007bff;
}

.call-list-jdi .custom-pagination .page-item .page-link:hover {
  background-color: #007bff;
}

.custom-hover:hover button {
  background-color: rgb(248, 234, 221);
}

.MuiButton-containedSizeSmall {
  padding: 3px 9px 3px 9px !important;
  font-size: 0.8125rem !important;
}

.makeStyles-buttonOrange-476 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.MuiButton-containedPrimary {
  border: 1px solid !important;
}

.color-disabled {
  input[type='text']:disabled,
  select:disabled,
  .MuiFormLabel-root.Mui-disabled,
  .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
    color: #263238;
  }

  .MuiButton-label {
    color: #f96f0f;
  }

  .MuiButton-containedPrimary .MuiButton-label {
    color: white;
  }

  .MuiButton-outlined.Mui-disabled,
  .MuiButton-containedPrimary {
    border-color: #f96f0f !important;
  }

  .makeStyles-buttonOrange-533.MuiButton-containedPrimary {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
  }
}

.receptionSheet td,
.receptionSheet th {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.receptionSheet td {
  height: 60px;
}

.receptionSheet .title td {
  height: 10px;
}

.receptionSheet .note {
  height: 150px;
}

.receptionSheet table {
  border: 3px solid black;
}

.receptionSheet table .width100 {
  padding: 0.7rem;
  width: 120px;
  font-weight: bold;
}

.receptionSheet table .width70 {
  padding: 0.7rem;
  width: 80px;
  font-weight: bold;
}

.receptionSheet table .width50 {
  padding: 0.7rem;
  width: 60px;
  font-weight: bold;
}

.receptionSheet table .width40 {
  padding: 0.7rem;
  width: 50px;
  font-weight: bold;
}

.receptionSheet table .width30 {
  padding: 0.7rem;
  width: 40px;
  font-weight: bold;
}

.receptionSheet table .width10 {
  padding: 0.7rem;
  width: 10px;
  font-weight: bold;
}

.receptionSheet table .bottom {
  border-bottom: 1px dotted #c9bfbf;
}

.receptionSheet table .borderRight {
  border-right: 1px solid #c9bfbf !important;
}

.receptionSheet table .middleAlign,
.receptionSheet table td {
  vertical-align: middle;
}

.pageA4 {
  table-layout: fixed;
  min-width: 36cm;
  min-height: 42cm;
  padding: 0.5cm;
  margin: 1cm auto;
  background: white;
  text-align: center;
  font-size: 16px;
}

.padding-07rem {
  padding: 0.7rem !important;
}

.search.selection.upward.dropdown .menu > .item {
  height: 37px !important;
}
