.datepickerOutBound .MuiOutlinedInput-adornedEnd {
  padding-right: 0 !important;
  background-color: #F2F2F2 !important;
}

.datepickerOutBound .MuiIconButton-root:hover {
  background-color: transparent;
}

.datepickerOutBound .MuiIconButton-root {
  padding: 3px;
}
.datepickerOutBound .MuiOutlinedInput-input {
  padding-left: 7px;
}
