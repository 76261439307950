@import 'auto-suggest';
@import 'hino-connect-date-picker';
@import 'hino-sos-custom';
@import 'hino-connect-custom';
@import 'outbound-custom';
@import 'out-bound-date-picker';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

a:not(.link-reception-number):hover {
  text-decoration: none !important;
}

#root {
  height: 100%;
}

:focus {
  outline: none !important;
}

.result-jmap-table {
  box-shadow: 0 0 1px #C5C5C5 !important;
  border: 1px solid #C5C5C5 !important;
  border-bottom: unset !important;
}

.MuiTableContainer-root {
  margin-left: 3px !important
}

.MuiTableCell-root {
  border-bottom: 1px solid #C5C5C5 !important;
}