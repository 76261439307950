.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 35px;
  padding: 10px 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  &:hover {
    border-color: #263238 !important;
  }
  &:focus {
    border-color: #3f62ac !important;
    border-width: 2px;
  }
}

.react-autosuggest__suggestions-container {
  display: none;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 48px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: none;
  max-height: 150px;
  overflow-y: auto;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 7px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
  border-radius: 4px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #0000001f;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}
